import {
    Main,
    GameSection,
    TileContainer,
    TileRow,
    Tile,
    KeyboardSection,
    KeyboardRow,
    KeyboardButton,
    Flex,
    ShareModal,
    Heading,
    ShareButton,
    } from "../styled";
    import { BackspaceIcon } from "../icons";
    import { Link, useNavigate } from "react-router-dom"
    import * as React from "react";
    import { Snackbar } from "@mui/material";
    import { useEffect, useRef, useState } from "react";
    import Modal from "react-modal";
    import logo from "../Swordle@2x.png";
    
    const API_URL = "https://api.dictionaryapi.dev/api/v2/entries/en";
    
    //set page title to Swordle
    document.title = "SeekOut Swordle";
    
    const keyboardRows = [
        ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p"],
        ["a", "s", "d", "f", "g", "h", "j", "k", "l"],
        ["enter", "z", "x", "c", "v", "b", "n", "m", "backspace"]
    ];
    
        //array of words to be guessed
        const words = ["hired", "hires", "state", "power", "apply", "roles", "offer", "query", "match", "fired", "promo", "title", 
        "field", "skill", "email", "group", "raise", "check", "focus", "human", "niche", "phone", "apply", "graph", "entry", "coder", "match", 
        "sales", "roles", "train", "email", "level", "award", "refer", "asset", "leads", "seeks", "years", "loops", "major", "pools", 
        "teams", "dream", "offer", "eager", "works", "poach", "adapt", "labor", "staff", "fills", "query", "techy", "ideal", "heart", "finds", 
        "delve", "equal", "shift", "media", "hunts", "value", "needs", "speed", "clone", "reach", "pride", "notes", "intro", "range", "scope", 
        "scour", "scrum", "nudge", "merit", "inbox", "users", "grows", "story", "trust"]
    
        //randomly select a word from the array
        const randomWord = words[Math.floor(Math.random() * words.length)];
        //set word of the day to the randomly selected word or local storage item if it exists
        const savedWord = JSON.parse(localStorage.getItem("wordOfTheDay"));
        const wordOfTheDay = savedWord || randomWord;
    
        //set word of the day to local storage 
        localStorage.setItem("wordOfTheDay", JSON.stringify(wordOfTheDay));
    
        console.log(wordOfTheDay);
    
    const allKeys = keyboardRows.flat();
    
    const wordLength = 5;
    
    const newGame = {
        0: Array.from({ length: wordLength }).fill(""),
        1: Array.from({ length: wordLength }).fill(""),
        2: Array.from({ length: wordLength }).fill(""),
        3: Array.from({ length: wordLength }).fill(""),
        4: Array.from({ length: wordLength }).fill(""),
        5: Array.from({ length: wordLength }).fill("")
    };
    
    const fetchWord = (word) => {
        return fetch(`${API_URL}/${word}`, {
        method: "GET"
        })
        .then((res) => res.json())
        .then((res) => res)
        .catch((err) => console.log("err:", err));
    };
    
    function Swordle() {

        //check if local storage has guesses saved
        const savedGuesses = JSON.parse(localStorage.getItem("guesses"));
    
        //check if local storage has guesses saved
        const savedMarkers = JSON.parse(localStorage.getItem("markers"));

        const savedWinModal = JSON.parse(localStorage.getItem("winModal"));
        const savedLoseModal = JSON.parse(localStorage.getItem("loseModal"));
    
        //set guesses to saved guesses or new game
        const [guesses, setGuesses] = useState(savedGuesses || newGame);
        const [markers, setMarkers] = useState({ ...savedMarkers || {
        0: Array.from({ length: wordLength }).fill(""),
        1: Array.from({ length: wordLength }).fill(""),
        2: Array.from({ length: wordLength }).fill(""),
        3: Array.from({ length: wordLength }).fill(""),
        4: Array.from({ length: wordLength }).fill(""),
        5: Array.from({ length: wordLength }).fill("")}
        });
    
        const [notInGameLetters, setNotInGameLetters] = useState([]);
        const [wrongPositionLetters, setWrongPositionLetters] = useState([]);
    
        const updatedNotInGameLetters = [...notInGameLetters];
        const updatedWrongPositionLetters = [...wrongPositionLetters];
    
        const [isModalVisible, setModalVisible] = useState(savedWinModal || false);
        const [isLoseVisible, setLoseVisible] = useState(savedLoseModal || false);
        const [isShared, setIsShared] = useState(false);
    
        const [open, setOpen] = useState(false);
    
        const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
    
        setOpen(false);
        };
    
        let letterIndex = useRef(0);

        let navigate = useNavigate();

        function clickOutOfModal(status) {
            if(status === true) {
                ResetGame()
                setModalVisible(false);
            } else {
                ResetGame()
                setLoseVisible(false);
            }
        }


        const ResetGame = () => {
            console.log("reset game");
            localStorage.clear()
            
            setGuesses(newGame);
            setMarkers({
                0: Array.from({ length: wordLength }).fill(""),
                1: Array.from({ length: wordLength }).fill(""),
                2: Array.from({ length: wordLength }).fill(""),
                3: Array.from({ length: wordLength }).fill(""),
                4: Array.from({ length: wordLength }).fill(""),
                5: Array.from({ length: wordLength }).fill("")
            });
            setNotInGameLetters([]);
            setWrongPositionLetters([]);
            letterIndex.current = 0;
            navigate("/");
        }
    
        //check if round is saved in local storage
        const savedRound = JSON.parse(localStorage.getItem("round"));
        let round = useRef(savedRound || 0);

        const [inputs] = useState({
            baseUrl: "//info.seekout.com",
            munchkinId: "726-VHL-519",
            formId: "1339",
            callback: ""
        });
        
        //load Marketo form, passing in the form ID, munchkin ID, and base URL, as well as a callback function and then input hidden field with value of win or lose and submit form
        const loadMarketoForm = (status) => {
            
            const { baseUrl, munchkinId, formId, callback } = inputs;
            const script = document.createElement("script");
            script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
            script.onload = () => {
                const MktoForms2 = window.MktoForms2;
                MktoForms2.loadForm(baseUrl, munchkinId, formId, callback);
                MktoForms2.whenReady((form) => {

                    form.setValues({ 
                        "FirstName": firstName, 
                        "LastName": lastName, 
                        "Email": email, 
                        "Misc_Ops_Field_String_02__c": status + " " + getDayOfYear(),
                        "utm_campaign__c": utmCampaign,
                    });

                    form.submit();
                });
            };
            document.body.appendChild(script);
        };
    
        const win = () => {
            document.removeEventListener("keydown", handleKeyDown);
            setModalVisible(true);
            localStorage.setItem("winModal", JSON.stringify(true));

            loadMarketoForm("won");
        }
    
        const lose = () => {
            document.removeEventListener("keydown", handleKeyDown);
            setLoseVisible(true);
            localStorage.setItem("loseModal", JSON.stringify(true));
        
            loadMarketoForm("lost");
        }
    
        const submit = () => {
            const _round = round.current;

            const updatedMarkers = {
                ...markers
            };
        
            const tempWord = wordOfTheDay.split("");
        
            const leftoverIndices = [];
        
            // Prioritize the letters in the correct spot
            tempWord.forEach((letter, index) => {
                const guessedLetter = guesses[_round][index];
        
                if (guessedLetter === letter) {
                    updatedMarkers[_round][index] = "green";
                    tempWord[index] = "";
                    document.querySelector(`.${guessedLetter}`).style.backgroundColor = "green";
                } else {
                    // We will use this to mark other letters for hints
                    leftoverIndices.push(index);
                }
            });
        
            if (updatedMarkers[_round].every((guess) => guess === "green") && _round <= 5) {
                //save markers to local storage
                localStorage.setItem("markers", JSON.stringify(updatedMarkers));
                //save guesses to local storage
                localStorage.setItem("guesses", JSON.stringify(guesses));
                setMarkers(updatedMarkers);
                win();
                return true;
            } else if (_round === 5 && updatedMarkers[_round].every((guess) => guess !== "green")) {
                //save markers to local storage
                localStorage.setItem("markers", JSON.stringify(updatedMarkers));
                //save guesses to local storage
                localStorage.setItem("guesses", JSON.stringify(guesses));
                setMarkers(updatedMarkers);
                lose(); 
            }

            if(_round < 5) {
                // Then find the letters in wrong spots
                if (leftoverIndices.length) {
                leftoverIndices.forEach((index) => {
                    const guessedLetter = guesses[_round][index];
                    const correctPositionOfLetter = tempWord.indexOf(guessedLetter);
        
                    if (
                    tempWord.includes(guessedLetter) &&
                    correctPositionOfLetter !== index
                    ) {
                    // Mark yellow when letter is in the word of the day but in the wrong spot
                    updatedMarkers[_round][index] = "yellow";
                    tempWord[correctPositionOfLetter] = "";
                    updatedWrongPositionLetters.push(guessedLetter);
                    } else {
                    // This means the letter is not in the word of the day.
                    updatedMarkers[_round][index] = "grey";
                    updatedNotInGameLetters.push(guessedLetter);
                    
                    }
                });
                }
            } else {
                //save markers to local storage
                localStorage.setItem("markers", JSON.stringify(updatedMarkers));
                //save guesses to local storage
                localStorage.setItem("guesses", JSON.stringify(guesses));
                setMarkers(updatedMarkers);
                lose();
            }          
        
            if(_round < 5) {
                setNotInGameLetters(updatedNotInGameLetters);
                setWrongPositionLetters(updatedWrongPositionLetters);
        
                //for each of the letters not in the word, change that key's color to grey
                if(updatedNotInGameLetters.length > 0) {
                updatedNotInGameLetters.forEach(element => {
                    //check if the letter is in the word of the day
                    if(!wordOfTheDay.includes(element)) {
                        document.querySelector(`.${element}`).style.backgroundColor = "#26303A";
                    }
                });
                }
        
                //for each of the letters in the wrong position, change that key's color to yellow
                if(updatedWrongPositionLetters.length > 0) {
                updatedWrongPositionLetters.forEach(element => {
                    //if the keyboard letter doesn't have a green background already, change it to yellow
                    if(document.querySelector(`.${element}`).style.backgroundColor !== "green") {
                        document.querySelector(`.${element}`).style.color = "#FFFFFF";
                        document.querySelector(`.${element}`).style.backgroundColor = "#b59f3b";
                    }
                });
                }
        
                setMarkers(updatedMarkers);
                //save markers to local storage
                localStorage.setItem("markers", JSON.stringify(updatedMarkers));
                //save guesses to local storage
                localStorage.setItem("guesses", JSON.stringify(guesses));
        
                round.current = _round + 1;
                //save round to local storage
                localStorage.setItem("round", JSON.stringify(round.current));
        
                letterIndex.current = 0;
            } else {
                //save markers to local storage
                localStorage.setItem("markers", JSON.stringify(updatedMarkers));
                //save guesses to local storage
                localStorage.setItem("guesses", JSON.stringify(guesses));
                setMarkers(updatedMarkers);
                lose();
            }
        };
    
        const erase = () => {
        const _letterIndex = letterIndex.current;
        const _round = round.current;
    
        if (_letterIndex !== 0) {
            setGuesses((prev) => {
            const newGuesses = { ...prev };
            newGuesses[_round][_letterIndex - 1] = "";
            return newGuesses;
            });
    
            letterIndex.current = _letterIndex - 1;
        }
        };
    
        const publish = (pressedKey) => {
        const _letterIndex = letterIndex.current;
        const _round = round.current;
    
        if (_letterIndex < wordLength) {
            setGuesses((prev) => {
            const newGuesses = { ...prev };
            newGuesses[_round][_letterIndex] = pressedKey.toLowerCase();
            return newGuesses;
            });
    
            letterIndex.current = _letterIndex + 1;
        }
        };
    
        const enterGuess = async (pressedKey) => {
            if (pressedKey === "enter" && !guesses[round.current].includes("")) {
                const validWord = await fetchWord(guesses[round.current].join(""));
        
                if (Array.isArray(validWord)) {
                submit();
                } else {
                setOpen(true);
                }
            } else if (pressedKey === "backspace") {
                erase();
            } else if (pressedKey !== "enter") {
                publish(pressedKey);
            }
            };
        
            const getDayOfYear = () => {
            const now = new Date();
            const start = new Date(now.getFullYear(), 0, 0);
            const diff = now - start;
            const oneDay = 1000 * 60 * 60 * 24;
            return Math.floor(diff / oneDay);
            };
        
            const handleClick = (key) => {
            const pressedKey = key.toLowerCase();
        
            enterGuess(pressedKey);
        };
    
        const copyMarkers = () => {
            let shareText = `Swordle - a SeekOut Game - ${getDayOfYear()}`;
            let shareText2 = `Swing by to play and win prizes!`;
            let shareGuesses = "";
        
            const amountOfGuesses = Object.entries(markers)
                .filter(([_, guesses]) => !guesses.includes(""))
                .map((round) => {
                const [_, guesses] = round;
        
                guesses.forEach((guess) => {
                    if (guess === "green") {
                    shareGuesses += "🟩";
                    } else if (guess === "yellow") {
                    shareGuesses += "🟨";
                    } else {
                    shareGuesses += "⬛";
                    }
                });
        
                shareGuesses += "\n";
        
                return "";
                });
        
            shareText += ` ${amountOfGuesses.length}/6\n${shareGuesses}`;
        
            shareText += shareText2;
        
            navigator.clipboard.writeText(shareText);
            setIsShared(true);
        };
    
        const handleKeyDown = (e) => {
        const pressedKey = e.key.toLowerCase();
    
        if (allKeys.includes(pressedKey)) {
            enterGuess(pressedKey);
        }
        };
    
        useEffect(() => {
            //check if #share element exists   
            if(document.querySelector("#share")) {
                Modal.setAppElement("#share");
            }
        
            document.addEventListener("keydown", handleKeyDown);
        
            return () => document.removeEventListener("keydown", handleKeyDown);
        });
        
        //check local storage for email and name
        const email = localStorage.getItem("email");
        const firstName = localStorage.getItem("firstName");
        const lastName = localStorage.getItem("lastName");
        const utmCampaign = localStorage.getItem("utmCampaign");

        if(email && firstName && lastName) {
            return (
            <>
                <Main>
                <header className="logo">
                    <img onClick={ResetGame} height="70" src={logo} alt="SeekOut Swordle Logo" />
                </header>
                <GameSection>
                    <TileContainer className="tiles">
                    {Object.values(guesses).map((word, wordIndex) => (
                        <TileRow key={wordIndex}>
                        {word.map((letter, i) => (
                            <Tile key={i} hint={markers[wordIndex][i]}>
                            {letter}
                            </Tile>
                        ))}
                        </TileRow>
                    ))}
                    </TileContainer>
                </GameSection>
                <KeyboardSection>
                    {keyboardRows.map((keys, i) => (
                    <KeyboardRow key={i}>
                        {i === 1 && <Flex item={0.5} />}
                        {keys.map((key) => (
                        <KeyboardButton
                            key={key}
                            onClick={() => handleClick(key)}
                            flex={["enter", "backspace"].includes(key) ? 1.5 : 1}
                            className={key}
                            aria-label={key + " key"}
                        >
                            {key === "backspace" ? <BackspaceIcon /> : key}
                        </KeyboardButton>
                        ))}
                        {i === 1 && <Flex item={0.5} />}
                    </KeyboardRow>
                    ))}
                </KeyboardSection>
                <Snackbar
                    open={open}
                    autoHideDuration={2000}
                    onClose={handleClose}
                    message="Word not in dictionary"
                    severity="error"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                />
                </Main>
                <div id="share">
                <Modal
                    isOpen={isModalVisible}
                    onRequestClose={() => clickOutOfModal(true)}
                    ariaHideApp={false}
                    style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)"
                    }
                    }}
                    contentLabel="Share"
                >
                    <ShareModal>
                    <Heading>You win!</Heading>
                    <h3>You've been entered into our raffle for a daily prize! Share your score</h3>
                    <ShareButton onClick={copyMarkers} disabled={isShared}>
                        {isShared ? "Copied!" : "Share"}
                        </ShareButton>
                        <h3>or</h3>
                        <ShareButton onClick={ResetGame}>New Player</ShareButton>
                    </ShareModal>
                </Modal>
                </div>
        
                <div id="lost">
                <Modal
                    isOpen={isLoseVisible}
                    onRequestClose={() => clickOutOfModal(false)}
                    ariaHideApp={false}
                    style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        right: "auto",
                        bottom: "auto",
                        marginRight: "-50%",
                        transform: "translate(-50%, -50%)"
                    }
                    }}
                    contentLabel="Lost"
                >
                    <ShareModal>
                    <Heading>You've lost</Heading>
                    <h3>Sorry, you lost; but come back another day during the event to try again!</h3>
                    <ShareButton onClick={ResetGame}>New Player</ShareButton>
                    </ShareModal>
                </Modal>
                </div>
            </>
        );
    } else {
        //redirect to homepage if no email, first name, or last name
        return ( 
            <>
                <Main>
                    <header className="logo">
                        <img height="70" src={logo} alt="SeekOut Swordle Logo" />
                    </header>
                    <h1 className="goBack"><Link to="/">Go back and register first</Link></h1>
                </Main>
            </>
        );
    }
};
export default Swordle;