import React from "react"
import { useEffect, useState } from "react"
import useMarketo from "../useMarketo"
import {useForm } from "react-hook-form"
import logo from "../Swordle@2x.png"
import {Main} from "../styled"
import "../index.css"

    
function Register() {
    const {handleSubmit, errors} = useForm();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");

        
    // get utm_campaign from url
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const URLParamCampaign = urlParams.get("utm_campaign");
        console.log(URLParamCampaign);
        localStorage.setItem("utmCampaign", URLParamCampaign);
    }, []);

    const onSubmit = (e, data) => {
        e.preventDefault();
        localStorage.setItem("firstName", data.querySelector("input[name='FirstName']").value)
        localStorage.setItem("lastName", data.querySelector("input[name='LastName']").value)
        localStorage.setItem("email", data.querySelector("input[name='Email']").value)
    
        //redirect to swordle page
        window.location.href = "/swordle"
    }
    
    function Form(props) {  
        const { baseUrl, munchkinId, formId } = props;
        useMarketo(props);
    
        return <form id={`mktoForm_${formId}`} />;
    }

    const [inputs, setInputs] = useState({
        baseUrl: "//info.seekout.com",
        munchkinId: "726-VHL-519",
        formId: "1339",
        callback: ""
    });

    //once loaded, get the form and on submit call the onSubmit function
    useEffect(() => {
        const form = document.getElementById(`mktoForm_${inputs.formId}`);
        if (form) {
            form.addEventListener("submit", (e) => {
                e.preventDefault();
                onSubmit(e, form);
                setFirstName(form.querySelector("input[name='FirstName']").value)
                setLastName(form.querySelector("input[name='LastName']").value)
                setEmail(form.querySelector("input[name='Email']").value)
            });
        }
    }, []);

    return (
        <>
            <Main>
            <header className="logo">
                <img height="70" src={logo} alt="SeekOut Swordle Logo" />
            </header>
                <Form {...inputs} />        
            </Main>
        </>
    );
}  

export default Register